<template>
  <b-overlay
      :show="products === null"
      rounded="sm"
  >

    <div v-if="products !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-3"
      >

        <h1 class="m-2">انتخاب محصولات</h1>

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchCommand"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="products"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >
          <template #cell(productId)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/${data.item.imageFile}`"
                    :variant="`success`"
                />
              </template>
              <small class="text-muted">@{{ data.item.productId }}</small>
            </b-media>
          </template>

          <!-- Column: product.price -->
          <template #cell(price)="data">
            <span>{{ makePrettyPrice(data.item.price) }} تومان</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link @click="addToCart(data.item)">
              <feather-icon icon="ShoppingBagIcon" size="20"/>
            </b-link>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>

    <div v-if="Cart.length !== 0">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <h2 class="m-2">سبد خرید کاربر</h2>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="Cart"
            responsive
            :fields="myCartTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: product.price -->
          <template #cell(amount)="data">
            <span>{{ makePrettyPrice(data.item.amount) }} تومان</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(delete)="data">
            <b-link @click="deleteFromCart(data.item)">
              <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
            </b-link>
          </template>

        </b-table>

        <h3 class="m-2">جمع کل : {{ makePrettyPrice(CartTotal) }} تومان</h3>

        <div class="mx-2 mb-2">
          <b-row>
            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="setOrder"
            >
              ثبت سفارش
            </b-button>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import {OrderChangeStatusRequest} from "@/libs/Api/Order";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {OrderDeleteRequest} from "@/libs/Api/Order";
import {OrderGetProductsForStudents} from "@/libs/Api/Order";
import {ProductGetAllRequest} from "@/libs/Api/Product";
import {OrderCreateByAdmin} from "@/libs/Api/Order";

export default {
  title: "ثبت سفارش کاربر - پنل ادمین مکس ",
  name: "Buy",
  data() {
    return {
      userId: this.$route.params.id,
      products: null,
      currentPage: 1,
      totalCounts: null,
      perPage: 50,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'productId', label: 'شناسه محصول'},
        {key: 'title', label: 'نام محصول'},
        {key: 'price', label: 'قیمت'},
        {key: 'actions', label: 'افزودن به سبد خرید'},
      ],
      myCartTableColumns: [
        {key: 'title', label: 'نام محصول'},
        {key: 'amount', label: 'قیمت'},
        {key: 'delete', label: 'حذف'},
      ],
      searchCommand: '',
      isAddNewUserSidebarActive: false,
      Cart: [],
    }
  },
  computed: {
    CartTotal() {
      let result = 0;
      this.Cart.forEach((item) => {
        result += item.amount * item.count
      })
      return result;
    },
    currentDate() {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },
  },
  async created() {
    await this.getProducts(this.perPage, this.currentPage, this.searchCommand);
  },
  methods: {
    refreshData() {
      this.getProducts(this.perPage, this.currentPage, this.searchCommand)
    },
    async getProducts(count, pageNumber, search) {
      let _this = this;
      let getProductData = {
        categoryId: 0,
        searchByTitle: search,
        pageNumber: pageNumber,
        count: count,
        type: 0,
        isFree: false,
      }

      let productGetAllRequest = new ProductGetAllRequest(_this);
      productGetAllRequest.setParams(getProductData)
      await productGetAllRequest.fetch(function (content) {
        _this.products = content.products;
        _this.totalCounts = content.productsCount;
      }, function (content) {
        console.log(content)
      });
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    async setOrder() {
      let _this = this;

      let data = {
        isDeleted: false,
        orderId: 0,
        isPayed: true,
        description: "",
        sumPrice: _this.CartTotal,
        purchasedByAdmin:true,
        isDelivered: false,
        userId: _this.userId,
        createDate: _this.currentDate,
        status: 2,
        address: null,
        subOrders: _this.Cart,
        addressInfo: ""
      }
      let orderCreateByAdmin = new OrderCreateByAdmin(_this);
      orderCreateByAdmin.setParams(data);
      await orderCreateByAdmin.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            icon: 'CheckIcon',
            variant: 'success',
            text: `سفارش شما ثبت شد.`,
          },
        })
        _this.Cart = [];
      }, function (error) {

      })
    },
    addToCart(data) {
      let _this = this;

      let tmpItem = {
        isDeleted: false,
        productId: 0,
        title: '',
        img: '',
        amount: 0,
        count: 1,
        courseScheduleId: 0,
        orderId: 0,
        orderItemId: 0,
      }

      tmpItem.productId = data.productId;
      tmpItem.title = data.title;
      if (data.isFree) {
        tmpItem.amount = 0
      } else {
        tmpItem.amount = data.price;
      }
      tmpItem.img = data.imageFile;


      const clone = {...tmpItem}

      //if it is not already in shopping cart
      function isAdded(array) {
        return array.productId === data.productId;
      }

      if (_this.Cart.find(isAdded)) {
        return _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            icon: 'CloseIcon',
            variant: 'danger',
            text: `این مورد در سبد خرید موجود است.`,
          },
        })
      } else {
        _this.Cart.push(clone)
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            icon: 'CheckIcon',
            variant: 'success',
            text: `به سبد خرید اضافه شد.`,
          },
        })
      }
      console.log(_this.Cart)
    },
    deleteFromCart(idx) {
      let _this = this;

      _this.Cart.splice(idx, 1)
      _this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          icon: 'CheckIcon',
          variant: 'success',
          text: `حذف شد.`,
        },
      })
    },
  },
  watch: {
    perPage: function (nv, ov) {
      this.getProducts(nv, this.currentPage, this.searchCommand);
    },
    currentPage: function (nv, ov) {
      this.getProducts(this.perPage, nv, this.searchCommand);
    },
    searchCommand: function (nv, ov) {
      this.getProducts(this.perPage, this.currentPage, nv)
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
